import React from 'react';
import Loader from './Loader';

function Backdrop({ loading }) {
  return (
    <>
      {loading && (
        <div className='backdrop'>
          <Loader />
        </div>
      )}
      <style>
        {`
          .backdrop {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
          }
        `}
      </style>
    </>
  );
}

export default Backdrop;
