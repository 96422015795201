import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import { theme } from '../utils/theme/Theme';
import Iconify from './Iconify';
import NavBar from './NavBar';

function Header() {
  const xs = useMediaQuery('(min-width:600px)');

  return (
    <>
      <Box
        backgroundColor={theme.colors.primary}
        display={'flex'}
        justifyContent={'center'}
      >
        <Box
          display={'flex'}
          flexDirection={xs ? 'row' : 'column'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={xs ? '70%' : '90%'}
          paddingY={1.5}
        >
          <Box display={'flex'} alignItems={'center'}>
            <Iconify
              icon={'ion:home-sharp'}
              sx={{
                marginInlineEnd: 1,
                fontSize: 14,
              }}
            />
            <Typography
              sx={{ color: 'white' }}
              fontSize={14}
              textAlign={'center'}
            >
              Address: Alkramreh street, Abu Nsair, Amman
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} marginTop={!xs && 1}>
            <IconButton
              LinkComponent={'a'}
              href='https://www.facebook.com/Dr.majali12/?_rdc=1&_rdr'
              target='_blank'
              disableRipple
              sx={{ padding: 0 }}
            >
              <Iconify
                icon={'uil:facebook'}
                sx={{
                  fontSize: 19,
                  transition: 'color 0.3s ease-in-out',
                  '&:hover': { color: 'black' },
                }}
              />
            </IconButton>
            <IconButton
              LinkComponent={'a'}
              href='https://www.instagram.com/dr_khaled.majali/'
              target='_blank'
              disableRipple
              sx={{ padding: 0 }}
            >
              <Iconify
                icon={'simple-icons:instagram'}
                sx={{
                  marginInlineStart: 1.5,
                  fontSize: 17,
                  transition: 'color 0.3s ease-in-out',
                  '&:hover': { color: 'black' },
                }}
              />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <NavBar />
    </>
  );
}

export default Header;
