import {
  Box,
  Button,
  Divider,
  Typography,
  useMediaQuery,
  Collapse,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/logo.jpeg';
import { theme } from '../utils/theme/Theme';
import Iconify from './Iconify';
import { Fragment, useState } from 'react';

function NavBar() {
  const buttons = [
    { name: 'Home', to: '/' },
    { name: 'Dr-Khaled', to: '/About' },
    { name: 'Pricing', to: '/Pricing' },
    { name: 'Procedures', to: '/Procedures' },
    { name: 'Blogs', to: '/Blogs' },
    { name: 'Contact Us', to: '/ContactUs' },
  ];

  const navigate = useNavigate();
  const xxs = useMediaQuery('(min-width:400px)');
  const xs = useMediaQuery('(min-width:600px)');
  const sm = useMediaQuery('(min-width:1100px)');
  const md = useMediaQuery('(min-width:1200px)');
  const lg = useMediaQuery('(min-width:1400px)');

  const [open, setOpen] = useState(false);

  return (
    <>
      <Box
        position={sm ? 'sticky' : 'relative'}
        top={0}
        zIndex={1000}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        paddingY={sm ? 3 : 6}
        backgroundColor='white'
      >
        <Box
          component={'img'}
          src={Logo}
          alt='Logo'
          height={80}
          position={'absolute'}
          left={
            lg ? 200 : md ? 140 : sm ? 100 : xs ? '38%' : xxs ? '32%' : '27%'
          }
          top={10}
          onClick={() => navigate('/')}
          sx={{ cursor: 'pointer' }}
        />
        {sm && (
          <Box display={'flex'}>
            {buttons?.map?.((button, index) => (
              <Button
                key={index}
                onClick={() => navigate(button.to)}
                disableRipple
                sx={{
                  '&:hover': { backgroundColor: 'white' },
                  fontWeight: 'bold',
                  fontSize: 18,
                  paddingX: 1.5,
                }}
              >
                {button.name}
              </Button>
            ))}
          </Box>
        )}
      </Box>
      {!sm && (
        <>
          <Box
            position='sticky'
            top={0}
            backgroundColor={theme.colors.primary}
            zIndex={1000}
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              paddingInlineStart={2}
              paddingInlineEnd={1}
              paddingY={1}
            >
              <Typography
                fontWeight={'bold'}
                fontSize={20}
                sx={{ color: 'white', fontFamily: 'Roboto' }}
              >
                Menu
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                  width: 40,
                  height: 40,
                  cursor: 'pointer',
                }}
                onClick={() => setOpen(!open)}
              >
                <Iconify
                  icon={'majesticons:menu-line'}
                  sx={{
                    position: 'absolute',
                    fontSize: 40,
                    transition: 'opacity 0.3s ease, transform 0.3s ease',
                    opacity: open ? 0 : 1,
                    transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
                  }}
                />

                <Iconify
                  icon={'iconoir:cancel'}
                  sx={{
                    position: 'absolute',
                    fontSize: 40,
                    transition: 'opacity 0.3s ease, transform 0.3s ease',
                    opacity: open ? 1 : 0,
                    transform: open ? 'rotate(0deg)' : 'rotate(90deg)',
                  }}
                />
              </Box>
            </Box>
            <Collapse in={open}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                zIndex={1000}
                backgroundColor={theme.colors.primary}
              >
                {buttons?.map?.((button, index) => (
                  <Fragment key={index}>
                    <Button
                      onClick={() => {
                        navigate(button.to);
                        setOpen(false);
                      }}
                      disableRipple
                      sx={{
                        '&:hover': { backgroundColor: 'white' },
                        fontWeight: 'bold',
                        paddingY: 1.5,
                        paddingX: 2,
                        justifyContent: 'start',
                        color: 'white',
                        borderRadius: 0,
                      }}
                    >
                      {button.name}
                    </Button>
                    <Divider sx={{ backgroundColor: 'white' }} />{' '}
                  </Fragment>
                ))}
              </Box>
            </Collapse>
          </Box>
        </>
      )}
    </>
  );
}

export default NavBar;
