import { Suspense, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Routes from './routes';
import { request } from './shared/api/request';
import Backdrop from './shared/components/Backdrop';
import Footer from './shared/components/Footer';
import Header from './shared/components/Header';

function App() {
  const location = useLocation();
  const path =
    location?.pathname?.split('/')?.[
      location?.pathname?.split('/')?.length - 1
    ];

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      setIsAuthenticated(false);
      setLoading(false);
      return;
    }

    request('check-user', 'GET', null, {
      Authorization: `Bearer ${token}`,
    })
      .then(() => {
        setIsAuthenticated(true);
      })
      .catch(() => {
        setIsAuthenticated(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [location]);

  if (loading) {
    return <Backdrop loading={loading} />;
  }

  return (
    <>
      <Suspense fallback={<Backdrop loading={loading} />}>
        {path?.toLocaleLowerCase() !== 'login' &&
          path?.toLocaleLowerCase() !== 'dashboard' && <Header />}
        <Routes
          isAuthenticated={isAuthenticated}
          setIsAuthenticated={setIsAuthenticated}
        />{' '}
        {path?.toLocaleLowerCase() !== 'login' &&
          path?.toLocaleLowerCase() !== 'dashboard' && <Footer />}
      </Suspense>
    </>
  );
}

export default App;
