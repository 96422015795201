export const request = async (
  url,
  method = 'GET',
  body = null,
  customHeaders = {}
) => {
  try {
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3000';

    const options = {
      method,
      headers: {
        ...customHeaders,
      },
    };

    if (body instanceof FormData) {
      options.body = body;
    } else if (body) {
      options.headers['Content-Type'] = 'application/json';
      options.body = JSON.stringify(body);
    }

    const response = await fetch(`${apiUrl}/${url}`, options);

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Request failed');
    }

    return response.json();
  } catch (error) {
    throw error;
  }
};
