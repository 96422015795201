import { lazy } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';

const Home = lazy(() => import('./views/home/Home'));
const ContactUs = lazy(() => import('./views/contact_us/ContactUs'));
const About = lazy(() => import('./views/about/About'));
const Pricing = lazy(() => import('./views/pricing/Pricing'));
const Procedures = lazy(() => import('./views/procedures/Procedures'));
const PostDetails = lazy(() => import('./views/post_details/PostDetails'));
const Blogs = lazy(() => import('./views/blogs/Blogs'));
const Login = lazy(() => import('./views/login/Login'));
const Dashboard = lazy(() => import('./views/dashboard/Dashboard'));

function Routes({ isAuthenticated, setIsAuthenticated }) {
  return useRoutes([
    { path: '/', element: <Home /> },
    { path: '/About', element: <About /> },
    { path: '/Pricing', element: <Pricing /> },
    { path: '/Procedures', element: <Procedures /> },
    { path: '/Procedures/:id', element: <PostDetails /> },
    { path: '/Blogs', element: <Blogs /> },
    { path: '/Blogs/:id', element: <PostDetails /> },
    { path: '/ContactUs', element: <ContactUs /> },
    {
      path: '/dashboard',
      element: isAuthenticated ? <Dashboard /> : <Navigate to='/Login' />,
    },
    {
      path: '/Login',
      element: isAuthenticated ? (
        <Navigate to='/dashboard' />
      ) : (
        <Login setIsAuthenticated={setIsAuthenticated} />
      ),
    },
  ]);
}

export default Routes;
