import {
  Box,
  Button,
  Divider,
  Grid2,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/logo.jpeg';
import { theme } from '../utils/theme/Theme';
import Iconify from './Iconify';

function Footer() {
  const navigate = useNavigate();
  const xs = useMediaQuery('(min-width:700px)');
  const sm = useMediaQuery('(min-width:900px)');
  const lg = useMediaQuery('(min-width:1400px)');

  return (
    <Box
      backgroundColor={theme.colors.primary}
      paddingX={lg ? 30 : sm ? 20 : 4}
      paddingTop={6}
      paddingBottom={1}
    >
      <Grid2 container columnSpacing={4} rowSpacing={4}>
        <Grid2 size={{ md: 12, lg: 6 }}>
          <Box
            component={'img'}
            src={Logo}
            alt='Logo'
            width={'50%'}
            onClick={() => navigate('/')}
            sx={{ cursor: 'pointer' }}
          />
          <Typography
            sx={{
              color: 'white',
              paddingY: 2,
              fontSize: 18,
              maxWidth: sm ? '80%' : '100%',
            }}
          >
            We believe in the power of a healthy smile to transform lives.
            Established with a vision to provide exemplary dental care in a warm
            and welcoming environment, our clinic has become a cornerstone of
            oral health excellence in the community.
          </Typography>
          <Box display={'flex'}>
            <Box
              display={'flex'}
              alignItems={'center'}
              backgroundColor={theme.colors.secondary}
              padding={1.5}
              borderRadius={1}
              marginInlineEnd={1}
              onClick={() =>
                window.open('https://www.facebook.com/Dr.majali12', '_blank')
              }
              sx={{ cursor: 'pointer' }}
            >
              <Iconify icon={'uil:facebook'} sx={{ fontSize: 20 }} />
            </Box>
            <Box
              display={'flex'}
              alignItems={'center'}
              backgroundColor={theme.colors.secondary}
              padding={1.5}
              borderRadius={1}
              onClick={() =>
                window.open(
                  'https://www.instagram.com/dr_khaled.majali/?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D',
                  '_blank'
                )
              }
              sx={{ cursor: 'pointer' }}
            >
              <Iconify icon={'simple-icons:instagram'} sx={{ fontSize: 20 }} />
            </Box>
          </Box>
        </Grid2>
        <Grid2 size={{ md: 12, lg: 6 }} width={'100%'}>
          <Typography
            sx={{
              fontSize: 28,
              fontWeight: 'bold',
              color: 'white',
              marginBottom: 2,
            }}
          >
            Location
          </Typography>
          <iframe
            title='Dr-Khaled'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3381.4999458958137!2d35.873719799999996!3d32.055724100000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151c9f50579aa63b%3A0xe832b28629f98a94!2z2LnZitin2K_YqSDYp9mE2K_Zg9iq2YjYsSDYrtin2YTYryDYp9mE2YXYrNin2YTZig!5e0!3m2!1sen!2ssa!4v1729262504999!5m2!1sen!2ssa'
            width='100%'
            height={sm ? 350 : 250}
            style={{ border: 0 }}
            allowfullscreen=''
            loading='lazy'
            referrerpolicy='no-referrer-when-downgrade'
          />
        </Grid2>
      </Grid2>
      <Divider sx={{ borderColor: 'white', marginTop: 4, marginBottom: 0 }} />
      <Box
        display={'flex'}
        flexDirection={xs ? 'row' : 'column'}
        justifyContent={xs ? 'space-between' : 'center'}
        alignItems={'center'}
        marginTop={!xs && 2}
      >
        <Typography
          sx={{
            color: 'white',
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          Copyright © Dr. Khaled all rights reserved.
        </Typography>

        <Box>
          <Button
            disableRipple
            onClick={() => navigate('/')}
            sx={{
              padding: 2,
              fontSize: 16,
              color: 'white',
              '&:hover': { backgroundColor: 'transparent', color: 'white' },
            }}
          >
            Home
          </Button>
          <Button
            disableRipple
            onClick={() => navigate('/About')}
            sx={{
              padding: 2,
              fontSize: 16,
              color: 'white',
              '&:hover': { backgroundColor: 'transparent', color: 'white' },
            }}
          >
            About
          </Button>
          <Button
            disableRipple
            onClick={() => navigate('/ContactUs')}
            sx={{
              padding: 2,
              fontSize: 16,
              color: 'white',
              '&:hover': { backgroundColor: 'transparent', color: 'white' },
            }}
          >
            Contact
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
