import { createTheme } from '@mui/material';

const COLORS = {
  primary: '#21cdc0',
  secondary: '#08449B',
  light: '#21cdc0',
  dark: '#61ce70',
  grayBg: '#F9F9F9',
  contrastText: '',
};

export const theme = createTheme({
  colors: COLORS,
  typography: {
    fontFamily: 'Nunito Sans',
    allVariants: {
      color: '#273353',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 'bold',
        },
        text: {
          color: '#18b3c3',
          '&:hover': {
            color: COLORS.secondary,
          },
        },
        contained: {
          backgroundColor: COLORS.primary,
          color: 'white',
        },
        outlined: {
          color: COLORS.primary,
          borderColor: COLORS.primary,
          '&:hover': {
            backgroundColor: COLORS.primary,
            color: 'white',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: COLORS.primary,
            },
            '&:hover fieldset': {
              borderColor: COLORS.primary,
            },
            '&.Mui-focused fieldset': {
              borderColor: COLORS.primary,
            },
          },
          '& .MuiInputLabel-root': {
            color: '#273353',
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: COLORS.secondary,
          },
        },
      },
    },
  },
});
